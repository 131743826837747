import React from "react"
import { usePreReadCreator } from "~/hooks/usePreReadCreator"

const PreReadCreator: React.FC = () => {
  usePreReadCreator()

  return null
}

export default PreReadCreator
