import React from "react"
import { DateTime } from "luxon"
import { Button } from "~/components/ui/button"
import { type Session } from "~/hooks/firestore/useSessions"

interface BookedSlotProps {
  session: Session
  onClick: (session: Session) => void
  slotHeight: number
  isSelected?: boolean
}

const MIN_SLOT_HEIGHT = 10

const BookedSlot: React.FC<BookedSlotProps> = ({
  session,
  onClick,
  slotHeight,
  isSelected = false,
}) => {
  // Calculate the top offset based on the start time of the slot
  // The start time is converted to the number of minutes above the hour
  const minutesAboveHour = session.start.minute
  const topOffset = (minutesAboveHour / 60) * slotHeight - 1
  const height = Math.ceil(
    Math.max(
      (session.duration.as("minutes") / 60) * (slotHeight + 1) - 1,
      MIN_SLOT_HEIGHT
    )
  )

  return (
    <Button
      onClick={(e) => {
        e.preventDefault()
        onClick(session)
      }}
      className={`
      absolute w-[95%]
      whitespace-normal
      overflow-hidden
      sm:rounded-sm
      rounded-[2px]
      transition-colors
      bg-opacity-60
      hover:bg-opacity-100
      ${session.personal ? "text-[#00382A] bg-[#ADDEBA] hover:bg-[#ADDEBA]" : "text-[#4D0066] bg-[#E6ADFF] hover:bg-[#E6ADFF]"}
      ${isSelected ? "shadow-md bg-opacity-100" : ""}
      z-10
      px-[1px] sm:px-1 md:px-2
      py-0 sm:py-0.5
      items-start
      justify-start
      ${height < MIN_SLOT_HEIGHT + 3 ? "py-0" : ""}
    `}
      style={{ top: `${topOffset}px`, height: `${height}px` }}
    >
      <div className="flex flex-col justify-start w-full h-full">
        {!session.personal ? (
          <>
            {session.clientName ? (
              <div className="flex flex-row justify-left">
                <p
                  className={`text-left text-[10px] sm:text-xs w-full leading-tight sm:truncate ${height <= 20 && "line-clamp-1"}`}
                  style={{ lineHeight: "10px" }}
                >
                  {session.clientName}
                </p>
                {height <= 10 && (
                  <p
                    className="text-left text-[10px] sm:text-xs w-full sm:w-[50px] leading-tight"
                    style={{ lineHeight: "10px" }}
                  >
                    {" "}
                    {session.start.toLocaleString(DateTime.TIME_SIMPLE)}
                  </p>
                )}
              </div>
            ) : (
              <p className="text-left text-[10px] sm:text-xs w-full leading-tight sm:truncate line-clamp-1">
                {session.title || "No title"}
                {height <= 20 && (
                  <span>
                    {" "}
                    {session.start.toLocaleString(DateTime.TIME_SIMPLE)}
                  </span>
                )}
              </p>
            )}
          </>
        ) : (
          <p
            className="text-left text-[10px] sm:text-xs text-primary-black line-clamp-2"
            style={{ lineHeight: "12px" }}
          >
            {session.title || "(No title)"}
            {height < 20 && (
              <span> {session.start.toLocaleString(DateTime.TIME_SIMPLE)}</span>
            )}
          </p>
        )}
        {height > 10 && height < 40 && (
          <p className="text-left text-[10px] w-full leading-tight sm:truncate line-clamp-1">
            {session.start.toLocaleString(DateTime.TIME_SIMPLE)} -{" "}
            {session.end?.toLocaleString(DateTime.TIME_SIMPLE)}
          </p>
        )}
        {height >= 40 && (
          <p className="text-left text-[10px] w-full leading-tight sm:truncate line-clamp-2 sm:line-clamp-2">
            {session.start.toLocaleString(DateTime.TIME_SIMPLE)} -{" "}
            {session.end?.toLocaleString(DateTime.TIME_SIMPLE)}
          </p>
        )}
      </div>
    </Button>
  )
}

export default BookedSlot
