import React, { useEffect, useState } from "react"
import { collection, doc, Timestamp, updateDoc } from "firebase/firestore"
import { Circle, LoaderCircle, Pause, XIcon } from "lucide-react"
import { isMobile } from "react-device-detect"
import { useLocation, useNavigate } from "react-router-dom"
import { useWakeLock } from "react-screen-wake-lock"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  toast,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import useMediaRecorder from "~/hooks/useMediaRecorder"
import useSoundDetection from "~/hooks/useSoundDetection"
import { useUserJourney } from "~/hooks/useUserJourney"
import { useUserProfile } from "~/hooks/useUserProfile"
import { useUserStatistics } from "~/hooks/useUserStatistics"
import { NoteStatus } from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import { createNewNote } from "~/services/notes.service"
import { UserStatisticsType } from "~/types/userTypes"
import { getColor } from "~/utils/getColors"
import { PROMPTS } from "~/utils/prompts"
import { RecordingSyncStatus } from "~/utils/recordingSyncProtocol"
import LanguageSelector from "../../components/LanguageSelector"
import AudioVolumeVisualizer from "./AudioVolumeVisualizer"
import RecorderInspiration from "./RecorderInspiration"
import RecorderOnboarding from "./RecorderOnboarding"
// styles
import "./recorder.css"
import { captureMessage } from "@sentry/react"
import { Crisp } from "crisp-sdk-web"
import { useAnalytics } from "use-analytics"
import { sentryDefaultTags } from "~/config/instrument"
import { z } from "zod"
import { useBeforeunload } from "~/hooks/useBeforeunload"

type UploadingState = {
  uploading: boolean
  bytesTransferred: number
  bytesTotal?: number
  error?: string
}

// Create a Timestamp from an object with seconds and nanoseconds
const preprocessTimestamp = (arg: unknown) => {
  if (
    typeof arg === "object" &&
    arg !== null &&
    "seconds" in arg &&
    "nanoseconds" in arg &&
    typeof arg.seconds === "number" &&
    typeof arg.nanoseconds === "number"
  ) {
    return new Timestamp(arg.seconds, arg.nanoseconds)
  }
  return arg
}

const prevPageStateSchema = z.object({
  clientId: z.string().optional(),
  clientName: z.string().optional(),
  title: z.string().optional(),
  sessionId: z.string().optional(),
  sessionStart: z
    .preprocess(preprocessTimestamp, z.instanceof(Timestamp))
    .optional(),
})

const Card = ({ children, ...props }: React.PropsWithChildren) => {
  return (
    <div
      className="recorder-card-bg relative flex-col w-full md:max-w-[768px] md:rounded-2xl flex justify-center h-full"
      {...props}
    >
      <div className="flex h-full flex-col justify-center gap-4 py-10 px-6 fixed z-50 md:relative bottom-0 left-0 right-0">
        {children}
      </div>
    </div>
  )
}

function Recorder() {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  const {
    recording,
    paused,
    recordedChunks,
    error,
    time,
    frequencyData,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    recordingStorage,
  } = useMediaRecorder({ audio: true, video: false })

  const { isSupported, request, release } = useWakeLock({
    // On iOS we will not get a wake lock if device is in low power mode, inform user. // TODO: Add link to Q&A
    onError: () =>
      isMobile &&
      toast.info(
        "Can not keep screen awake. You may loose your recording if the screen turns off.",
        { duration: 5000 }
      ),
  })

  const [uploadingState, setUploadingState] = useState<UploadingState>({
    uploading: false,
    bytesTransferred: 0,
  })
  const [recordedTime, setRecordedTime] = useState<string>("00:00")
  const [userProfile, updateUserProfile] = useUserProfile()
  const [userLanguageCode, setUserLanguageCode] = useState<string>(
    userProfile?.dictationLanguage ?? "en"
  )
  const [recordingId, setRecordingId] = useState<string>()

  // Passed from previous page
  const [clientId, setClientId] = useState<string | undefined>(undefined)
  const [clientName, setClientName] = useState<string | undefined>(undefined)
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [sessionId, setSessionId] = useState<string | undefined>(undefined)
  const [sessionStart, setSessionStart] = useState<Timestamp | undefined>(
    undefined
  )

  // indicate that the user is done recording and make sure the recording
  // cannot be started again
  const [doneRecording, setDoneRecording] = useState(false)

  const [, updateUserJourney] = useUserJourney()
  const [userStatistics] = useUserStatistics()
  const navigate = useNavigate()

  // Check if a client ID is passed from the previous page
  const { state } = useLocation()

  useEffect(() => {
    const parsedState = prevPageStateSchema.safeParse(state)
    if (parsedState.success) {
      setClientId(parsedState.data?.clientId)
      setClientName(parsedState.data?.clientName)
      setTitle(parsedState.data?.title)
      setSessionId(parsedState.data?.sessionId)
      setSessionStart(parsedState.data?.sessionStart)
    }
  }, [state])

  //
  // Notify user if no sound is detected
  const notifySoundDetected = (message: string) => {
    toast.success(message, { duration: 5000 })
  }

  const notifyNoSoundDetected = (message: string) => {
    toast.warning(message, { duration: 5000 })
  }

  useBeforeunload(() => {
    if (recording || paused || (doneRecording && uploadingState.uploading)) {
      return "Recording is not fully saved and data may be lost."
    }
  })

  useSoundDetection(
    frequencyData,
    time,
    notifySoundDetected,
    notifyNoSoundDetected
  )
  const handleCreateWrittenNote = () => {
    handleCreateWrittenNoteAsync().catch((err) => {
      toast.error("Error creating written note")
      console.error(err)
    })
  }

  const handleCreateWrittenNoteAsync = async () => {
    if (!currentUser) {
      return
    }
    const noteRef = await createNewNote({
      currentUserId: currentUser.uid,
      noteStatus: NoteStatus.Edited,
      clientId,
      title,
      clientName,
      sessionId,
      sessionStart,
      userStatistics: userStatistics ?? undefined,
      dictationLanguage: userProfile?.dictationLanguage ?? "",
    })

    await updateDoc(noteRef, {
      editedTranscription: "",
    })

    void track("Note New_written")

    navigate("/notes/" + noteRef.id)
  }

  //
  // Handle recording start and stop
  const handleStartRecording = async () => {
    if (!currentUser) {
      return
    }
    if (isSupported) {
      await request()
    }

    // Auto-generate a recording ID using firestore
    const docRef = doc(collection(db, "recordings"))
    const newRecordingId = docRef.id

    setRecordingId(newRecordingId)
    startRecording(currentUser.uid, newRecordingId)
  }

  const handleStopRecording = () => {
    handleStopRecordingAsync().catch((err) => {
      toast.error("Error stopping recording")
      console.error(err)
    })
  }

  const handleStopRecordingAsync = async () => {
    if (!currentUser) {
      return
    }
    if (isSupported) {
      await release()
    }
    stopRecording()

    setDoneRecording(true)

    if (recordingId === undefined || recordingStorage === null) {
      throw new Error("Recording ID not initialized.")
    }

    try {
      if (userStatistics?.totalSavedNotes === 1) {
        await updateUserJourney({ secondNoteRecorded: true })
      }
    } catch {
      console.error("Error updating user journey state")
    }

    const noteRef = await createNewNote({
      currentUserId: currentUser.uid,
      noteId: recordingId,
      noteStatus: NoteStatus.Uploading,
      userStatistics: userStatistics ?? undefined,
      dictationLanguage: userProfile?.dictationLanguage ?? "",
      duration: time,
      clientId,
      title,
      clientName,
      sessionId,
      sessionStart,
    })

    try {
      void track("Note New_recorded", {
        language: userProfile?.dictationLanguage ?? "auto",
      })

      setUploadingState({ uploading: true, bytesTransferred: 0 })

      // wait for the recording to be fully persisted before navigating back
      // TODO: should this be a hook instead?
      await recordingStorage.flush()
    } catch (err: unknown) {
      if (err instanceof Error) {
        captureMessage(`Recorder failed flushing recording`, {
          level: "warning",
          tags: {
            ...sentryDefaultTags,
            recording_function: "recorder_stop",
            recording_id: recordingId,
            recording_error: err.message,
          },
        })
      }
    }

    let recordingStatus: RecordingSyncStatus | undefined = undefined

    try {
      recordingStatus = await recordingStorage.status()
    } catch (err: unknown) {
      if (err instanceof Error) {
        captureMessage(`Recorder failed retrieving recording status`, {
          level: "warning",
          tags: {
            ...sentryDefaultTags,
            recording_function: "recorder_stop",
            recording_id: recordingId,
            recording_error: err.message,
          },
        })
      }
    }

    if (recordingStatus === undefined) {
      // failed to upload/write recording to storage
      // TODO: Download pop-up and then navigate to /notes
      toast.error(
        "Failed to upload the note. Please contact support for further info."
      )
      // Update note document with upload complete
      await updateDoc(noteRef, {
        status: NoteStatus.Error,
      })

      navigate("/notes")
    } else if (recordingStatus.state === "syncing") {
      // immediately navigate away to the notes listing page since the
      // upload occurs in the background
      navigate("/notes")
    } else {
      setUploadingState({
        uploading: true,
        bytesTransferred: recordingStatus.bytesWritten,
        bytesTotal: recordingStatus.totalBytes,
      })

      // Update note document with upload complete
      await updateDoc(noteRef, {
        status: NoteStatus.Processing,
        storageRef: recordingStatus.path,
        recording: {
          recordingId,
          storagePath: recordingStatus.path,
          bytesUploaded: recordingStatus.bytesWritten,
          totalBytes: recordingStatus.totalBytes,
        },
      })

      // Delete the local file
      await recordingStorage?.delete()

      navigate("/notes")
    }
  }

  const handleLanguageSelect = (
    _newLanguage: string,
    newLanguageCode: string
  ) => {
    setUserLanguageCode(newLanguageCode)
    updateUserProfile({ dictationLanguage: newLanguageCode })
  }

  function shouldDisplayRecorderInspiration(
    userStatistics: UserStatisticsType | null,
    recording: boolean,
    recordedChunksLength: number
  ) {
    return (
      userStatistics?.totalSavedNotes === 0 &&
      (recording || recordedChunksLength !== 0)
    )
  }

  useEffect(() => {
    // Uploading, so set a timer
    if (uploadingState.uploading && recordingStorage !== null) {
      const timer = setTimeout(() => {
        recordingStorage
          .status()
          .then((status: RecordingSyncStatus) => {
            setUploadingState({
              ...uploadingState,
              uploading: status.state === "syncing",
              bytesTransferred: status.bytesWritten,
              bytesTotal: status.totalBytes,
            })
          })
          .catch(() => {
            setUploadingState({
              uploading: false,
              bytesTransferred: 0,
              error: "Error uploading recording",
            })
          })
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [uploadingState, recordingStorage])

  useEffect(() => {
    const recordedSeconds = Math.floor(time / 1000)

    // Calculated number of recorded minutes
    const recordedMinutes = Math.floor(recordedSeconds / 60)

    // Calculated number of recorded seconds
    const recordedSecondsRemainder = recordedSeconds % 60

    setRecordedTime(
      `${recordedMinutes.toString().padStart(2, "0")}:${recordedSecondsRemainder
        .toString()
        .padStart(2, "0")}`
    )
  }, [time])

  const closeAndHideChat = () => {
    if (Crisp.chat.isChatOpened()) {
      Crisp.chat.close()
    }
    if (Crisp.chat.isVisible()) {
      Crisp.chat.hide()
    }
  }

  useEffect(() => {
    // Set a timeout to close the chat after 2 seconds
    const timeout = setTimeout(() => {
      closeAndHideChat()
    }, 2000)

    // Close and hide chat on component mount
    closeAndHideChat()

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const triggerCrispChat = () => {
    if (!Crisp.chat.isVisible()) {
      Crisp.chat.show()
    }
    if (!Crisp.chat.isChatOpened()) {
      Crisp.chat.open()
    }
  }

  const showRecorderInspiration = shouldDisplayRecorderInspiration(
    userStatistics,
    recording,
    recordedChunks.length
  )

  const showWelcomeMessage =
    userStatistics?.totalSavedNotes === 0 &&
    !recording &&
    recordedChunks.length === 0

  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-soft_green-500 md:bg-primary-cream-300">
        <Card>
          <Button
            size="icon"
            variant="ghost"
            onClick={() => navigate("/")}
            className={`absolute top-4 right-4 ${showWelcomeMessage || showRecorderInspiration ? "invisible" : "visible"}`}
          >
            <XIcon
              className="size-8"
              strokeWidth={1.35}
            />
          </Button>

          {
            <div
              className={`flex flex-col items-center justify-center ${(showWelcomeMessage || showRecorderInspiration) && "invisible"}`}
            >
              <h5 className="text-center text-2xl font-['Platypi'] text-black pb-2">
                New note
              </h5>

              <LanguageSelector
                disabled={recording}
                selectedLanguageCode={userLanguageCode}
                onLanguageSelect={handleLanguageSelect}
              />
            </div>
          }

          <div className="flex flex-col items-center justify-center min-w-full flex-grow pt-4 px-2">
            {showRecorderInspiration && (
              <RecorderInspiration languageCode={userLanguageCode} />
            )}
            {
              /* SHOW WELCOME MESSAGE OR AUDIO VISUALIZER */
              showWelcomeMessage ? (
                <RecorderOnboarding />
              ) : (
                <div className="mt-1">
                  <AudioVolumeVisualizer
                    frequencyData={frequencyData}
                    barColor={
                      recording && !paused
                        ? getColor("bright_coral", 500)
                        : getColor("transparent_gray")
                    }
                  />
                </div>
              )
            }
          </div>

          <div className="flex flex-col items-center justify-center">
            <div
              className={`pt-2 pb-8 mt-5 ${(showWelcomeMessage || showRecorderInspiration) && "hidden"}`}
            >
              <p className="text-black text-4xl font-medium font-mono tracking-widest">
                {recordedTime}
              </p>
            </div>
            <div className="flex flex-row justify-between min-w-full">
              <div className="flex-1"></div>
              {/* This div acts as a spacer */}
              <Button
                onClick={
                  paused
                    ? resumeRecording
                    : recording
                      ? pauseRecording
                      : handleStartRecording
                }
                disabled={doneRecording}
                className={`recorder-button bg-bright_coral-500 hover:bg-bright_coral-600 text-white`}
              >
                {paused ? (
                  <span className="text-[1.2rem]">Resume</span>
                ) : recording ? (
                  <Pause
                    className="w-7 h-7"
                    fill="currentColor"
                  />
                ) : doneRecording ? (
                  <LoaderCircle className="w-7 h-7 animate-spin" />
                ) : (
                  <Circle
                    className="w-7 h-7"
                    fill="currentColor"
                  />
                )}
              </Button>
              <div className="flex-1 justify-center flex">
                <button
                  onClick={handleStopRecording}
                  className={`text-primary-black text-[22px] mr-3 ${recording ? "visible" : "invisible"}`}
                >
                  Done
                </button>
              </div>
            </div>

            <div className="flex justify-center md:justify-end min-w-full mt-3">
              <button
                onClick={handleCreateWrittenNote}
                className={`text-primary-black text-md md:mr-3 ${recording || time > 0 || doneRecording || showWelcomeMessage ? "invisible" : "visible"}`}
              >
                Create a new written note instead?
              </button>
              <button
                onClick={triggerCrispChat}
                className={`text-primary-black text-md md:mr-3 ${showWelcomeMessage ? "visible" : "hidden"}`}
              >
                Need help?
              </button>
            </div>
          </div>
        </Card>
      </div>

      <RecordAlertDialog
        error={error}
        triggerCrispChat={triggerCrispChat}
      />
    </>
  )
}

function RecordAlertDialog({
  error,
  triggerCrispChat,
}: {
  error: string | null
  triggerCrispChat: () => void
}) {
  const navigate = useNavigate()

  return (
    <AlertDialog
      aria-labelledby="close-modal-title"
      open={error !== null}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          navigate("/")
        }
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          {
            // Handle not allowed error separately
            error?.match(/NotAllowed/) ? (
              <>
                <AlertDialogTitle>
                  You have not allowed microphone access
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {isMobile ? (
                    PROMPTS.MIC_ACCESS_MESSAGE
                  ) : (
                    <>
                      Click on the microphone or settings icon in the address
                      bar and select <strong>Allow</strong>
                    </>
                  )}
                </AlertDialogDescription>
              </>
            ) : (
              <>
                <AlertDialogTitle id="close-modal-title">
                  Error recording audio
                </AlertDialogTitle>
                <AlertDialogDescription>{error}</AlertDialogDescription>
              </>
            )
          }
          <button
            onClick={triggerCrispChat}
            className={"text-primary-black text-md mt-2"}
          >
            Need help?
          </button>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogAction
            onClick={(e) => {
              e.preventDefault()
              window.location.reload()
            }}
          >
            Okay
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default Recorder
