import * as React from "react"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { useLocation } from "react-router-dom"
import { Toaster, TooltipProvider } from "~/components/ui"
import { UpdateNotifier } from "~/components/UpdateNotifier"
import PreReadCreator from "./components/PreReadCreator"
import { queryClient } from "./lib/react-query"

const UPDATE_NOTIFY_WHITELISTED_ROUTES = ["/notes"]

// TODO: Should we add a type for children? React.ReactNode?
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export function Providers({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation()

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider delayDuration={100}>
        {children}
        <Toaster />
        <PreReadCreator />

        <ReactQueryDevtools buttonPosition={"bottom-right"} />
        {UPDATE_NOTIFY_WHITELISTED_ROUTES.includes(location.pathname) ? (
          <UpdateNotifier />
        ) : null}
      </TooltipProvider>
    </QueryClientProvider>
  )
}
