import React from "react"
import { DateTime, Duration } from "luxon"
import { Button } from "~/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select"
import { cn } from "~/components/ui/utils"
import { useBooking } from "~/context/BookingContext"

interface TimeDetailsProps {
  start: DateTime
  duration: Duration
}

const TimeDetails: React.FC<TimeDetailsProps> = ({ start, duration }) => {
  const { updateCurrentSession } = useBooking()

  const startOptions = [0, 15, 30]
  const durationOptions = [30, 45, 60]

  // Extend duration options to include 5 minute increments up to 2 hours
  // and 30 minute increments up to 8 hours
  const extendedDurationOptions = [
    ...Array.from({ length: 24 }, (_, i) => (i + 1) * 5),
    ...Array.from({ length: 12 }, (_, i) => 120 + (i + 1) * 30),
  ]

  const handleStartMinuteChange = (minute: number) => {
    updateCurrentSession({ start: start?.set({ minute }) })
  }

  const handleDurationChange = (minutes: number) => {
    updateCurrentSession({ duration: Duration.fromObject({ minutes }) })
  }

  const uses12Hour = new Intl.DateTimeFormat(undefined, {
    hour: "numeric",
  }).resolvedOptions().hour12

  return (
    <div>
      <h3 className="mb-4 capitalize">
        {start.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
      </h3>
      <div className="flex flex-wrap items-center gap-0.5 sm:gap-2 mb-4">
        <p className="w-[50px] sm:w-[70px]">Start</p>
        {startOptions.map((minute) => (
          <Button
            key={minute}
            variant={start?.minute === minute ? "secondary" : "ghost"}
            onClick={() => handleStartMinuteChange(minute)}
            className="text-xs sm:text-sm px-2 sm:px-3 py-1 sm:py-2 w-[58px] sm:w-[65px] font-['SF Pro'] font-bold rounded-3xl lowercase"
          >
            {uses12Hour ? (
              <p>{start?.set({ minute }).toFormat("h:mm a")}</p>
            ) : (
              <p>
                {start?.set({ minute }).toFormat("H")}
                <sup>{start?.set({ minute }).toFormat("mm")}</sup>
              </p>
            )}
          </Button>
        ))}
        <div className="w-[2px] h-[35px] bg-[#8c867c] hidden sm:block" />
        <Select
          value={
            startOptions.includes(start?.minute ?? 0)
              ? undefined
              : start?.minute?.toString()
          }
          onValueChange={(value) => handleStartMinuteChange(parseInt(value))}
        >
          <SelectTrigger
            className={cn(
              "w-[90px] sm:w-[110px] text-xs sm:text-sm bg-0 border-0 rounded-3xl data-[placeholder]:text-primary-black font-['SF Pro'] font-bold mx-1  justify-center gap-1 lowercase",
              !startOptions.includes(start?.minute ?? 0) &&
                "bg-secondary text-white"
            )}
          >
            <SelectValue placeholder="other" />
          </SelectTrigger>
          <SelectContent>
            {Array.from({ length: 12 }, (_, i) => i * 5).map((minute) => (
              <SelectItem
                key={minute}
                value={minute.toString()}
              >
                {uses12Hour ? (
                  start?.set({ minute }).toFormat("h:mm a")
                ) : (
                  <p>
                    {start?.set({ minute }).toFormat("h")}
                    <sup>{start?.set({ minute }).toFormat("mm")}</sup>
                  </p>
                )}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex flex-wrap items-center gap-0.5 sm:gap-2 mb-4">
        <p className="w-[50px] sm:w-[70px]">Duration</p>
        {durationOptions.map((mins) => (
          <Button
            key={mins}
            variant={duration?.as("minutes") === mins ? "secondary" : "ghost"}
            onClick={() => handleDurationChange(mins)}
            className="text-xs sm:text-sm px-2 sm:px-3 py-1 sm:py-2 w-[58px] sm:w-[65px] font-['SF Pro'] font-bold rounded-3xl"
          >
            {mins}
          </Button>
        ))}

        <div className="w-[2px] h-[35px] bg-[#8c867c] hidden sm:block" />

        <Select
          value={
            durationOptions.includes(duration?.as("minutes") ?? 0)
              ? undefined
              : duration?.as("minutes").toString()
          }
          onValueChange={(value) => handleDurationChange(parseInt(value))}
        >
          <SelectTrigger
            className={cn(
              "w-[90px] sm:w-[110px] text-xs sm:text-sm text-primary-black bg-0 border-0 rounded-3xl data-[placeholder]:text-primary-black font-bold mx-1 justify-center gap-1",
              !durationOptions.includes(duration?.as("minutes") ?? 0) &&
                "bg-secondary text-white"
            )}
          >
            <SelectValue placeholder="other" />
          </SelectTrigger>
          <SelectContent>
            {extendedDurationOptions.map((duration) => (
              <SelectItem
                key={duration}
                value={duration.toString()}
              >
                {duration > 60
                  ? Duration.fromDurationLike({ minutes: duration }).toFormat(
                      "h'h' mm"
                    )
                  : Duration.fromDurationLike({ minutes: duration }).toFormat(
                      "m'm'"
                    )}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}

/*
  
*/
export default TimeDetails
