import * as React from "react"
import { $createHeadingNode } from "@lexical/rich-text"
import {
  BoldItalicUnderlineToggles,
  ButtonWithTooltip,
  convertSelectionToNode$,
  currentBlockType$,
  headingsPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  useCellValue,
  usePublisher,
  type MDXEditorMethods,
  type MDXEditorProps,
} from "@mdxeditor/editor"
import { HeadingIcon, PilcrowIcon } from "@radix-ui/react-icons"
import { useKeyPress } from "ahooks"
import { $createParagraphNode } from "lexical"
import { cn } from "./utils"
import "@mdxeditor/editor/style.css"

type RichEditorProps = Omit<MDXEditorProps, "theme" | "markdown"> & {
  value: string
  isInvalid?: boolean
  onRequestSave?: () => void
}

const plugins = [
  // Basic
  headingsPlugin(),
  listsPlugin(),
  quotePlugin(),
  thematicBreakPlugin(),
  markdownShortcutPlugin(),
  // Advanced
  linkPlugin(),
  tablePlugin(),
] as const

const RichEditor = React.forwardRef<MDXEditorMethods, RichEditorProps>(
  ({ isInvalid, onRequestSave, value, ...props }, ref) => {
    const wrapperRef = React.useRef<HTMLDivElement>(null)

    // Request save on Ctrl+S or Cmd+S
    useKeyPress(
      ["ctrl.s", "meta.s"],
      (e) => {
        e.preventDefault()
        onRequestSave?.()
      },
      { target: wrapperRef }
    )

    return (
      <div
        ref={wrapperRef}
        className="h-full"
      >
        <MDXEditor
          ref={ref}
          {...props}
          markdown={value ?? ""}
          plugins={[...plugins, ...(props.plugins ?? [])]}
          contentEditableClassName={cn(
            "prose max-w-full leading-[1.25]",
            props?.contentEditableClassName
          )}
          className={cn(
            "border-0 border-transparent hover:border-border rounded p-0",
            isInvalid && "border-destructive",
            props.className
          )}
        />
      </div>
    )
  }
)
RichEditor.displayName = "RichEditor"

function HeadingPToggle() {
  const convertSelectionToNode = usePublisher(convertSelectionToNode$)
  const currentBlockType = useCellValue(currentBlockType$)

  return (
    <ButtonWithTooltip
      title="Toggle paragraph/heading"
      onClick={() => {
        if (currentBlockType !== "paragraph") {
          convertSelectionToNode(() => $createParagraphNode())
        } else {
          convertSelectionToNode(() => $createHeadingNode("h3"))
        }
      }}
    >
      {currentBlockType?.startsWith("h") ? <PilcrowIcon /> : <HeadingIcon />}
    </ButtonWithTooltip>
  )
}

function SimpleEditorToolbar() {
  return (
    <>
      <HeadingPToggle />
      <ListsToggle options={["bullet"]} />
      <BoldItalicUnderlineToggles options={["Bold"]} />
    </>
  )
}

export { RichEditor, type RichEditorProps, SimpleEditorToolbar }
