import React from "react"
import { Button } from "~/components/ui/button"

interface SlotProps {
  slotHeight: number
  onClick: () => void
  isWorkingHour: boolean
}

const Slot: React.FC<SlotProps> = ({ slotHeight, onClick, isWorkingHour }) => {
  return (
    <Button
      onClick={onClick}
      className={`
      absolute inset-0 overflow-hidden rounded-none
      ${isWorkingHour ? "bg-background" : "bg-[#f5f5f5]"}
      transition-colors      
      h-[${slotHeight}px]
      hover:rounded-md
      hover:bg-primary/60
      `}
    >
      <div
        className={
          "absolute inset-0 flex items-center justify-center text-[8px] sm:text-xs"
        }
      ></div>
    </Button>
  )
}

export default Slot
