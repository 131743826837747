import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { DateTime, Duration } from "luxon"
import { type Session } from "~/hooks/firestore/useSessions"

export type BookingState =
  | "none" // For no booking
  | "new" // For booking new sessions
  | "edit" // For editing existing sessions
  | "view" // For viewing existing sessions

interface BookingContextType {
  currentSession?: Session
  updateCurrentSession: (info: Partial<Session>) => void
  resetCurrentSession: () => void
}

const BookingContext = createContext<BookingContextType | undefined>(undefined)

export const BookingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentSession, setCurrentSession] = useState<Session | undefined>(
    undefined
  )

  /**
   * Used to build the current session
   */
  const updateCurrentSession = useCallback((info: Partial<Session>) => {
    setCurrentSession((prev) => ({
      ...prev,
      ...info,
      id: info.id ?? prev?.id ?? "",
      duration:
        info.duration ?? prev?.duration ?? Duration.fromObject({ minutes: 60 }),
      start: info.start ?? prev?.start ?? DateTime.local().startOf("hour"),
      title: info.title ?? prev?.title ?? "",
      description: info.description ?? prev?.description ?? "",
      personal: info.personal ?? prev?.personal ?? false,
      clientId:
        info.clientId === "undefined"
          ? undefined
          : (info.clientId ?? prev?.clientId),
      clientName: info.clientName ?? prev?.clientName ?? "",
    }))
  }, [])

  const resetCurrentSession = useCallback(() => {
    setCurrentSession(undefined)
  }, [])

  const contextValue = useMemo(
    () => ({
      currentSession,
      updateCurrentSession,
      resetCurrentSession,
    }),
    [currentSession, resetCurrentSession, updateCurrentSession]
  )

  return (
    <BookingContext.Provider value={contextValue}>
      {children}
    </BookingContext.Provider>
  )
}

export const useBooking = () => {
  const context = useContext(BookingContext)
  if (context === undefined) {
    throw new Error("useBooking must be used within a BookingProvider")
  }
  return context
}
