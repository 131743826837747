import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  collection,
  CollectionReference,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
} from "firebase/firestore"
import { Trash2Icon } from "lucide-react"
import { matchSorter } from "match-sorter"
import { useNavigate } from "react-router-dom"
import { DeleteClientModal } from "~/components/client-ui/DeleteClientModal"
import SearchField from "~/components/SearchField"
import { Button, toast } from "~/components/ui"
import { TEMPLATE_START_DATE, THRESHOLD_TO_SHOW_CLIENT_SEARCH } from "~/config"
import { useAuth } from "~/context/AuthContext"
import { useClients, type Client } from "~/hooks/firestore/useClients"
import { useNotesSubscription } from "~/hooks/useNotes"
import useSubscriptionHandler from "~/hooks/useSubscriptionHandler"
import { useUserJourney } from "~/hooks/useUserJourney"
import { useUserStatistics } from "~/hooks/useUserStatistics"
import BackToTop from "~/pages/BackToTop"
import { db } from "~/services/firebase"
import {
  SCROLL_DOWN_THRESHOLD,
  SCROLL_UP_THRESHOLD,
} from "~/utils/const-strings"
import { isValidNoteForDisplay } from "~/utils/noteUtils"
import NoteListItem from "./NoteListItem"
import { Improvement, Note, NoteStatus } from "./types"
// styles
import "../../index.css"
import "./note.css"
import { InfiniteData, useQueryClient } from "@tanstack/react-query"
import { useAnalytics } from "use-analytics"
import NoteErrorDialog from "./NoteErrorDialog"
import NoteBeingProcessedDialog from "./NoteProcessingDialog"
import NotesOnboarding from "./NotesOnboarding"

interface ClientMap extends Client {
  hasNotes?: boolean
  noteId?: string
  noteCount?: number
}

interface ClientCardProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onEnter?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

const ClientCard: React.FC<React.PropsWithChildren<ClientCardProps>> = ({
  children,
  onClick,
  onEnter,
  ...props
}) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className="flex flex-col w-full h-full rounded-xl shadow justify-center bg-white px-6 py-5 mb-[8px] cursor-pointer"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onEnter) {
          onEnter(e)
        }
      }}
      {...props}
    >
      {children}
    </div>
  )
}

const capitalize = (s: string) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const highlightText = (text: string, query: string) => {
  const _text = capitalize(text)
  if (!query) return _text

  const escapedValues = query.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
  const regex = new RegExp("(" + escapedValues + ")", "gi")

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: `${_text}`.replace(
          regex,
          "<mark class='bg-gray-200'>$1</mark>"
        ),
      }}
    />
  )
}

export default function Notes() {
  const navigate = useNavigate()
  const { track } = useAnalytics()

  const { currentUser } = useAuth()
  const [userStatistics] = useUserStatistics()
  const [userJourney, updateUserJourney] = useUserJourney()
  const { allowNotesRecording } = useSubscriptionHandler()

  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [lastScrollTime, setLastScrollTime] = useState<number>(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const lastScrollTop = useRef(0)
  const topBarRef = useRef<HTMLDivElement>(null)
  const [noteWithError, setNoteWithError] = useState<Note | undefined>(
    undefined
  )
  const [noteBeingProcessed, setNoteBeingProcessed] = useState<
    Note | undefined
  >(undefined)

  const [searchClient, setSearchClient] = useState<string>("")
  const queryClient = useQueryClient()

  const queryClients = useClients()
  const clients = React.useMemo(
    () => queryClients.data ?? [],
    [queryClients.data]
  )

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isNotesLoading,
  } = useNotesSubscription({
    onData: (newNotes) => {
      // TODO: Decide on strategy for processing improvement requested notes
      void processImprovementRequestedNotes(newNotes)
    },
  })

  const notes = React.useMemo(() => {
    if (!data) return []

    const items = data.pages
      .flatMap((page) => page.data ?? [])
      .filter((note) => isValidNoteForDisplay(note))

    // Group notes by client
    const itemsGroups: Record<string, Note[]> = items.reduce(
      (acc: Record<string, Note[]>, note) => {
        const clientId = note?.clientId ?? `note:${note.id}`

        acc[clientId] = acc[clientId] ?? []
        acc[clientId].push(note)
        return acc
      },
      {}
    )

    // Flatten the groups by take the latest note of each client
    return Object.values(itemsGroups)
      .map((_notes: Note[]) => {
        if (_notes.length === 1) return _notes[0]

        const isNewer = (a: Note, b: Note) =>
          a?.createdAt &&
          b?.createdAt &&
          a.createdAt.toDate().getTime() > b.createdAt.toDate().getTime()

        return _notes.reduce<Note | null>(
          (acc, note) => (!acc || isNewer(note, acc) ? note : acc),
          null
        )
      })
      .filter((note) => note !== null) as Note[]
  }, [data])

  const allClients = React.useMemo(
    () =>
      clients?.map((client) => {
        const hasNote = notes.find((note) => note.clientId === client.id)
        if (hasNote) {
          return {
            ...client,
            hasNotes: true,
            noteId: hasNote.id,
            noteCount: notes.filter((note) => note.clientId === client.id)
              .length,
          }
        }
        return client
      }),
    [clients, notes]
  )

  // Go through notes and see if any have status "improvement_requested"
  // If improvement is available, update note to 'improved' and set improvedTranscription
  const processImprovementRequestedNotes = async (
    data: InfiniteData<{ data: Note[]; nextCursor: number | null }, number>
  ) => {
    if (!data) return

    const items = data.pages
      .flatMap((page) => page.data ?? [])
      .filter((note) => isValidNoteForDisplay(note))

    const newImprovementRequestedNotes = items.filter(
      (note) => note.status === NoteStatus.ImprovementRequested
    )

    if (newImprovementRequestedNotes.length === 0) {
      return
    }

    let improvementsReceived = 0
    await Promise.all(
      newImprovementRequestedNotes.map(async (note) => {
        const improvementsRef = collection(
          db,
          `users/${currentUser?.uid}/notes/${note.id}/improvements`
        ) as CollectionReference<Improvement>

        // A note can have multiple improvements, but we only need the first one
        const q = query(improvementsRef, limit(1))

        try {
          const snap = await getDocs(q)

          for (const d of snap.docs) {
            const improvement = d.data()
            const noteRef = doc(
              db,
              `users/${currentUser?.uid}/notes/${note.id}`
            )

            // Update Firestore doc
            await updateDoc(noteRef, {
              status: NoteStatus.Improved,
              improvedTranscription: improvement.improvedTranscription,
            })

            improvementsReceived++
          }
        } catch {
          console.error("Error getting improvements")
        }
      })
    )

    if (improvementsReceived > 0) {
      void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
    }
  }

  const deferredSearchClient = React.useDeferredValue(searchClient)
  const filteredClients = useMemo(() => {
    if (!`${deferredSearchClient ?? ""}`.trim()) return allClients
    return matchSorter(allClients ?? [], deferredSearchClient, {
      keys: ["name"],
      threshold: 2,
    })
  }, [allClients, deferredSearchClient])

  const handleNewNoteClick = () => {
    if (!allowNotesRecording()) {
      toast.error("Upgrade your subscription to create more notes.")
      void track("Note_List Attempt_to_create_note_without_subscription")
      return
    }

    void track("Note_List Navigate_to_recorder")
    navigate("/recorder")
  }

  const handleOpenClientNotes = (client: ClientMap) => {
    if (!client.hasNotes) {
      toast.error("No Notes Attached to this Client.")
      return
    }
    navigate(`/notes/${client.noteId}`)
  }

  const handleScroll = useCallback(() => {
    if (!scrollContainerRef.current) return

    const scrollTop = scrollContainerRef.current.scrollTop
    const isScrollingDown = scrollTop > lastScrollTop.current

    if (isScrollingDown && scrollTop > SCROLL_DOWN_THRESHOLD && !isScrolled) {
      if (Date.now() - lastScrollTime > 500) {
        setIsScrolled(true)
        setLastScrollTime(Date.now())
      }
    } else if (
      !isScrollingDown &&
      scrollTop < SCROLL_UP_THRESHOLD &&
      isScrolled
    ) {
      if (Date.now() - lastScrollTime > 500) {
        setIsScrolled(false)
        setLastScrollTime(Date.now())
      }
    }

    lastScrollTop.current = scrollTop
  }, [isScrolled, lastScrollTime])

  useEffect(() => {
    const node = scrollContainerRef.current
    if (node) {
      node.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (node) {
        node.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  const isLoading = isNotesLoading && !notes.length
  const [deleteClient, setDeleteClient] = useState<string | null>(null)

  const handleNoteClick = async (note: Note) => {
    if (
      note.status === NoteStatus.Uploading ||
      note.status === NoteStatus.Processing
    ) {
      void track("Note_List Attempt_to_view_note_while_being_processed")
      setNoteBeingProcessed(note)
      return
    }

    if (!userJourney?.noteDetailsViewed) {
      await updateUserJourney({ noteDetailsViewed: true })
    }

    if (note.status === NoteStatus.Error) {
      setNoteWithError(note)
      return
    }

    if (
      !note.viewed &&
      note.status === NoteStatus.Transcribed &&
      note.serverTimestamp?.toDate() > TEMPLATE_START_DATE &&
      userStatistics &&
      userStatistics.totalSavedNotes > 1
    ) {
      void track("Note_List Navigate_to_transform_selection")
      navigate(`/notes/${note.id}/template-selection`)
    } else {
      void track("Note_List Navigate_to_note_details")
      navigate(`/notes/${note.id}`)
    }
  }

  return (
    <div
      className="overflow-y-auto h-full bg-primary-cream-300"
      ref={scrollContainerRef}
    >
      <NoteBeingProcessedDialog
        note={noteBeingProcessed}
        setNoteBeingProcessed={setNoteBeingProcessed}
      />

      <NoteErrorDialog
        noteWithError={noteWithError}
        setNoteWithError={setNoteWithError}
      />

      <div className="flex flex-col">
        <div className="flex h-full flex-col justify-center gap-4 p-3 md:p-6 pt-0">
          {!isFocused && (
            <div
              ref={topBarRef}
              className={`sticky top-[-1px] z-40 flex items-center justify-between notes-heading-bg transition-height ${isScrolled ? "topbar-small" : "topbar-large"}`}
            >
              <h2
                className={`text-primary-black transition-text ${isScrolled ? "text-[2.5rem]" : "text-[2.5rem] sm:text-[64px]"} font-medium leading-normal tracking-tighter font-platypi px-2 md:px-0`}
              >
                Notes
              </h2>
              <Button
                onClick={handleNewNoteClick}
                disabled={!allowNotesRecording()}
                className="text-lg font-['SF Pro'] w-[133px] h-[60px]"
              >
                New note
              </Button>
            </div>
          )}

          {
            // Only show the client search field if the threshold is met
            clients.length > THRESHOLD_TO_SHOW_CLIENT_SEARCH && (
              <SearchField
                isFocused={isFocused}
                searchClient={searchClient}
                setSearchClient={setSearchClient}
                setIsFocused={setIsFocused}
              />
            )
          }

          {isLoading ? (
            <div className="p-5">
              <p>Getting your notes...</p>
            </div>
          ) : (
            <>
              <div className="flex-1 overflow-y-auto">
                {notes.length === 0 &&
                !isFocused &&
                userStatistics &&
                userStatistics?.totalSavedNotes > 1 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60vh",
                    }}
                  >
                    <h4>
                      {isNotesLoading
                        ? "Getting your notes..."
                        : "No notes yet!"}
                    </h4>
                  </div>
                ) : !isFocused ? (
                  <ul>
                    {notes?.map((note, index) => (
                      <NoteListItem
                        key={note.id}
                        note={note}
                        showSetClient={
                          !note.viewed &&
                          !note.clientId &&
                          index === 0 &&
                          (userStatistics?.totalSavedNotes ?? 0) > 1
                        }
                        onClick={() => handleNoteClick(note)}
                      />
                    ))}
                  </ul>
                ) : filteredClients?.length === 0 ? (
                  <div className="flex flex-col items-center">
                    <h4>No matching clients!</h4>
                  </div>
                ) : (
                  <ul>
                    {filteredClients
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((client, index) => (
                        <ClientCard
                          key={index}
                          onClick={() => {
                            if (deleteClient) return
                            handleOpenClientNotes(client)
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <h5 className="font-platypi text-xl md:text-2xl font-bold text-black">
                              {highlightText(client.name, searchClient)}
                            </h5>

                            {!("hasNotes" in client && client.hasNotes) && (
                              <>
                                <Button
                                  size="icon"
                                  variant="ghost"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setDeleteClient(client.id)
                                  }}
                                  className="h-[32px]"
                                >
                                  <Trash2Icon size={20} />
                                  <span className="sr-only">Delete</span>
                                </Button>

                                <DeleteClientModal
                                  clientId={client.id}
                                  isOpen={deleteClient === client.id}
                                  onOpenChange={() => setDeleteClient(null)}
                                />
                              </>
                            )}
                          </div>
                        </ClientCard>
                      ))}
                  </ul>
                )}

                {
                  /* Show onboarding component if user has not completed onboarding */
                  userJourney && !userJourney.onboardingCompleted && (
                    <NotesOnboarding notes={notes} />
                  )
                }

                {!isFocused && notes.length !== 0 && hasNextPage && (
                  <div style={{ padding: "10px", textAlign: "center" }}>
                    <Button
                      onClick={() => fetchNextPage}
                      isLoading={isFetchingNextPage}
                    >
                      Load more notes
                    </Button>
                  </div>
                )}
              </div>

              <BackToTop scrollContainerRef={scrollContainerRef} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
