export const PROMPTS = {
  MIC_ACCESS_MESSAGE:
    "Please navigate to your device's settings and enable microphone access for this site",
  REMOVE_CLIENT_FROM_NOTE:
    "Are you sure you want to remove this note from client?",
  ENTER_EMAIL_PASSWORD: "Enter your email and password.",
  ENTER_VERIFICATION_CODE: "Enter verification code",
  ENTER_CODE: "Enter the code sent to your phone.",
  ENTER_PHONE_NUMBER: "Enter a mobile phone number that you have access to.",
  VERIFY_PHONE_NUMBER: "Verify your phone number",
  REMOVE_CLIENT: "Do you want to remove this client?",
  MISTAKES_INFO: "Joy can make mistakes. Always check the final note.",
  PREREAD_MISTAKES_INFO: "Joy can make mistakes. Verify important information.",
}
