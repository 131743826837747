import * as Sentry from "@sentry/browser"
import { useQuery } from "@tanstack/react-query"
import { collection, getDocs, query } from "firebase/firestore"
import { db } from "~/services/firebase"

interface BannerData {
  message: string
  backgroundColor: string
  backgroundHoverColor: string
  textColor: string
  active: boolean
}

const isValidColor = (color: string): boolean => /^#[0-9A-F]{6}$/i.test(color)

function useBannerMessages() {
  return useQuery({
    queryKey: ["bannerMessages"],
    refetchInterval: 300_000, // 5 minutes
    queryFn: async () => {
      const bannersRef = collection(db, "bannerMessages")
      const q = query(bannersRef)

      try {
        const snap = await getDocs(q)

        if (snap.empty) {
          return null
        }

        // Go through all banners and set the first active
        for (const doc of snap.docs) {
          const bannerData = doc.data() as BannerData

          if (bannerData.active) {
            // Verify that colors are valid and message is not empty
            if (
              isValidColor(bannerData.backgroundColor) &&
              isValidColor(bannerData.backgroundHoverColor) &&
              isValidColor(bannerData.textColor) &&
              bannerData.message.length > 0
            ) {
              return bannerData
            }
          }
        }

        return null
      } catch (error) {
        Sentry.captureException(error)
        throw error
      }
    },
  })
}

function BannerMessage() {
  const { data: bannerData } = useBannerMessages()

  if (!bannerData) {
    return null
  }

  return (
    <div
      className={`flex items-center justify-center p-1 h-16 w-full rounded-none transition-colors motion-reduce:transition-none`}
      style={{
        backgroundColor: bannerData.backgroundColor,
        color: bannerData.textColor,
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = bannerData.backgroundHoverColor
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = bannerData.backgroundColor
      }}
    >
      <p className={`text-center text-lg text-[${bannerData.textColor}]`}>
        {bannerData.message}
      </p>
    </div>
  )
}

export default BannerMessage
