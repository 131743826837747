import { useState } from "react"
import ThumbsdownFilledIcon from "~/assets/icons/hand-thumbsdown-filled.svg?react"
import ThumbsdownIcon from "~/assets/icons/hand-thumbsdown.svg?react"
import ThumbsupFilledIcon from "~/assets/icons/hand-thumbsup-filled.svg?react"
import ThumbsupIcon from "~/assets/icons/hand-thumbsup.svg?react"
import { Button } from "~/components/ui"

export const FeedbackButtons = ({
  onFeedback,
  feedback,
  buttonClassName = "",
  iconClassName = "",
}: {
  onFeedback: (type: string) => void
  feedback: string
  buttonClassName?: string
  iconClassName?: string
}) => {
  const [isAnimating, setIsAnimating] = useState(false)

  const handleLikeClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsAnimating(true)
    onFeedback("like")
  }

  const handleAnimationEnd = () => setIsAnimating(false)

  return (
    <div className="flex justify-center items-center gap-1">
      <Button
        onClick={handleLikeClick}
        size="icon"
        variant="ghost"
        className={`${isAnimating ? "motion-safe:animate-tada" : ""} ${buttonClassName}`}
        disabled={feedback !== ""}
        onAnimationEnd={handleAnimationEnd}
      >
        {feedback === "like" ? (
          <ThumbsupFilledIcon className={iconClassName} />
        ) : (
          <ThumbsupIcon className={iconClassName} />
        )}
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onFeedback("dislike")
        }}
        size="icon"
        variant="ghost"
        disabled={feedback !== ""}
        className={buttonClassName}
      >
        {feedback === "dislike" ? (
          <ThumbsdownFilledIcon className={iconClassName} />
        ) : (
          <ThumbsdownIcon className={iconClassName} />
        )}
      </Button>
    </div>
  )
}
