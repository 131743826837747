import React, { useEffect, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { motion } from "framer-motion"
import { useAnalytics } from "use-analytics"
import { Button } from "~/components/ui"
import { useUpdateNoteStatus } from "~/hooks/useNotes"
import {
  formatTimestampAsNoteTitle,
  formatTimestampAsNoteTitleShort,
} from "~/utils/helperFunctions"
import { LocalRecordings } from "~/utils/recordings"
import ClientModal from "./ClientModal"
import { Note, NoteStatus } from "./types"

interface NoteListItemProps {
  note: Note
  showSetClient: boolean
  fetchNotes?: () => void
  onClick: () => void
}

interface ClickableItemProps {
  children: React.ReactNode
  onClick?: () => void
}

const ClickableItem: React.FC<ClickableItemProps> = ({ children, onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()

    if ((e.target as HTMLElement).tagName === "BUTTON") {
      return
    }

    onClick?.()
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (e.key === "Enter" || e.key === " ") {
      onClick?.()
    }
  }

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      className="flex flex-col w-full h-full rounded-xl shadow justify-center bg-white px-6 py-5 mb-[8px] cursor-pointer"
    >
      {children}
    </div>
  )
}

function NoteListItemProcessing({
  text,
  note,
}: Readonly<{ text: string; note: Note }>) {
  const textArray = text.split("")
  const [iteration, setIteration] = useState(0)
  const queryClient = useQueryClient()

  const handleComplete = () => {
    // Trigger re-render to reset animation
    setTimeout(() => {
      setIteration((prev) => prev + 1)
    }, 500) // Pause for 0.5 second before restarting animation
  }

  const updateNote = useUpdateNoteStatus({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["NOTE", note.id] })
    },
  })

  // Check status for local error
  useEffect(() => {
    async function checkStatus() {
      const local = new LocalRecordings()
      local
        .status(note.id)
        .then(async (status) => {
          if (status?.state === "error") {
            await updateNote.mutateAsync({
              noteId: note.id,
              noteStatus: NoteStatus.Error,
            })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    void checkStatus()
  }, [note, updateNote])

  return (
    <div className="flex flex-col">
      <motion.p
        key={iteration} // Reset animation on each iteration
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{
          delay: text.length * 0.1 + 2, // Keep delay consistent with the text animation below
          duration: 1, // Duration of the fade-out effect
        }}
        onAnimationComplete={handleComplete} // Restart the animation after fade-out
        className="text-xl md:text-2xl font-bold font-platypi"
      >
        {textArray.map((el, i) => (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: text.length * 0.01,
              delay: i * 0.1, // delay each character
              ease: "linear",
              repeatDelay: text.length * 0.1 + 2,
            }}
            key={i}
            style={{ display: "inline-block" }} // ensure elements don't break lines
          >
            {el === " " ? "\u00A0" : el}
          </motion.span>
        ))}
      </motion.p>

      <p className="text-medium-grey-500 text-sm font-normal">
        Recorded{" "}
        {formatTimestampAsNoteTitle(note.createdAt).toLocaleLowerCase()}
      </p>
    </div>
  )
}

function NoteListItemWithClient({ note }: Readonly<{ note: Note }>) {
  return (
    <div>
      <h5 className="font-platypi text-xl md:text-2xl font-bold text-black flex sm:mb-1 md:mb-2 capitalize">
        {note.clientName ?? note.title}
      </h5>
      <p className="text-medium-grey-500 text-sm font-normal capitalize">
        {formatTimestampAsNoteTitleShort(note.createdAt)}
      </p>
      {note.status === NoteStatus.Error && (
        <p className="text-red-500 text-sm font-normal">
          Error processing note
        </p>
      )}
    </div>
  )
}

function NoteListItemNoClient({ note }: Readonly<{ note: Note }>) {
  return (
    <div className="flex flex-col gap-1">
      {note.sessionId && note.title ? (
        <div className="flex flex-col gap-1">
          <h5 className="font-platypi flex items-center text-xl md:text-2xl font-bold text-black">
            {note.title}
          </h5>
          <p className="text-medium-grey-500 text-sm font-normal font-['SF Pro'] capitalize">
            {formatTimestampAsNoteTitleShort(note.createdAt)}
          </p>
        </div>
      ) : (
        <h5 className="font-platypi flex items-center text-xl md:text-2xl font-bold text-black capitalize">
          {note.title === "My first note"
            ? "My first note"
            : formatTimestampAsNoteTitle(note.createdAt)}
        </h5>
      )}
      {note.status === NoteStatus.Error && (
        <p className="text-red-500 text-sm font-normal">
          Error processing note
        </p>
      )}
    </div>
  )
}

function NoteListItem({
  note,
  showSetClient,
  onClick,
}: Readonly<NoteListItemProps>) {
  const [openModal, setOpenModal] = useState(false)
  const { track } = useAnalytics()

  const renderNoteContent = () => {
    if (
      note.status === NoteStatus.Processing ||
      note.status === NoteStatus.Uploading
    ) {
      return (
        <div>
          <div className="sm:hidden">
            <NoteListItemProcessing
              text={
                note.status === NoteStatus.Processing
                  ? "Typing up..."
                  : "Uploading..."
              }
              note={note}
            />
          </div>
          <div className="hidden sm:block">
            <NoteListItemProcessing
              text={
                note.status === NoteStatus.Processing
                  ? "Typing up your note..."
                  : "Uploading your recording..."
              }
              note={note}
            />
          </div>
        </div>
      )
    }
    if (note.clientId) {
      return <NoteListItemWithClient note={note} />
    }
    return <NoteListItemNoClient note={note} />
  }

  const handleClick = () => {
    onClick()
  }

  const handleShowSetClient = () => {
    setOpenModal(true)
    void track("Note_List Client_modal_opened")
  }

  return (
    <>
      <ClientModal
        noteId={note.id}
        isOpen={openModal}
        onOpenChange={setOpenModal}
      />

      <ClickableItem onClick={handleClick}>
        <div className="flex flex-row justify-between items-center h-full">
          {renderNoteContent()}

          {showSetClient && note.status !== NoteStatus.Error && (
            <Button
              variant="secondary"
              className="self-center py-4 px-5 rounded-[10px] text-[16px] sm:text-[19px] font-['Archivo'] my-[-6px] leading-snug"
              onClick={(event) => {
                event.stopPropagation()
                handleShowSetClient()
              }}
            >
              Set client
            </Button>
          )}
        </div>
      </ClickableItem>
    </>
  )
}

export default NoteListItem
