import React, { useEffect } from "react"
import { Timestamp } from "@firebase/firestore"
import { Trash2Icon, X } from "lucide-react"
import { DateTime, Duration } from "luxon"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "~/components/ui/button"
import { ConfirmationModal } from "~/components/ui/confirmation-modal"
import { useBooking } from "~/context/BookingContext"
import {
  useClientById,
  useLastNoteByClientId,
} from "~/hooks/firestore/useClients"
import { Note } from "~/pages/Notes/types"

interface BookingDetailsViewerProps {
  onClose: () => void
  onEdit: () => void
  onDelete: () => void
}

interface ClientData {
  id?: string
  name?: string
  phone?: string
  email?: string

  [key: string]: string | undefined
}

const BookingDetailsViewer: React.FC<BookingDetailsViewerProps> = ({
  onClose,
  onEdit,
  onDelete,
}) => {
  const navigate = useNavigate()
  const { currentSession } = useBooking()
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false)
  const start = currentSession?.start ?? DateTime.now()
  const duration =
    currentSession?.duration ?? Duration.fromObject({ minutes: 60 })
  const end = start.plus(duration)
  const [clientId, setClientId] = React.useState<string>(
    currentSession?.clientId || ""
  )

  const client = useClientById({
    clientId,
    reactQuery: {
      enabled: Boolean(clientId),
    },
  })
  const clientData = (client.data as ClientData) ?? {}

  const lastNote = useLastNoteByClientId({
    clientId,
    reactQuery: {
      enabled: Boolean(clientId),
    },
  })
  const lastNoteData = (lastNote.data as Note) ?? {}

  useEffect(() => {
    setClientId(currentSession?.clientId || "")
  }, [currentSession])

  const handleEdit = () => {
    onEdit()
  }

  const handleClose = () => {
    onClose()
  }

  const handleDelete = () => {
    onDelete()
  }

  const navigateToRecorder = () => {
    const sessionStart = currentSession?.start.toJSDate()
    const state = {
      clientId,
      clientName: clientData.name,
      title: currentSession?.title,
      sessionId: currentSession?.id,
      sessionStart: sessionStart ? Timestamp.fromDate(sessionStart) : undefined,
    }
    navigate("/recorder", { state })
  }

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmDelete}
        onOpenChange={setShowConfirmDelete}
        title={
          currentSession?.personal ? "Delete event?" : "Delete client session?"
        }
        description=""
        confirmButton="Delete"
        closeButton="Cancel"
        onConfirm={handleDelete}
      />

      <div className=" sm:mx-4 flex flex-col justify-center item-center h-full w-full sm:w-[600px]">
        <div className="px-2 sm:px-4 pb-4 pb-4 flex flex-col item-center self-center gap-2 sm:gap-4 w-full h-full overflow-y-auto">
          <div className="sm:pt-1 flex flex-row self-center justify-between w-full md:max-w-[600px]">
            <div className="flex flex-row">
              <Button
                variant={"ghost"}
                onClick={handleEdit}
                className="text-xs sm:text-sm text-red-500 hover:text-red-500"
              >
                Edit
              </Button>
              <Button
                variant={"ghost"}
                className="text-xs sm:text-sm text-red-500 hover:text-red-500"
                onClick={(e) => {
                  e.preventDefault()
                  setShowConfirmDelete(true)
                }}
              >
                <Trash2Icon className="size-4 text-red-500" />
                Delete
              </Button>
            </div>
            <Button
              variant="ghost"
              onClick={handleClose}
              className=""
            >
              <X className="h-4 w-4" />
            </Button>
          </div>

          <div className="flex flex-col justify-between self-center w-full h-full gap-4 md:max-w-[600px] px-4">
            <div className="flex flex-col w-full gap-1 sm:gap-2">
              <h2
                className={`text-primary-black text-[1.5rem] font-medium leading-normal tracking-tighter font-platypi line-clamp-1 hover:line-clamp-3`}
              >
                {clientData.name && clientData.name}
                {!clientData.name &&
                  currentSession?.title &&
                  currentSession.title}
                {!clientData.name && !currentSession?.title && "(No title)"}
              </h2>

              {clientData.name && currentSession?.title && (
                <h3 className="text-[1rem]">{currentSession.title}</h3>
              )}

              <p className="capitalize">
                {start.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} &middot;{" "}
                {start.toLocaleString(DateTime.TIME_SIMPLE)} -{" "}
                {end.toLocaleString(DateTime.TIME_SIMPLE)}
              </p>

              <p className="text-sm">{currentSession?.description}</p>
            </div>

            <div className="flex flex-col justify-start self-start">
              {lastNoteData?.id && (
                <Link
                  to={`/notes/${lastNoteData.id}`}
                  className="underline underline-always"
                >
                  View client notes
                </Link>
              )}
            </div>

            <div className="flex flex-col w-full gap-1 mt-4 mb-4">
              <Button
                onClick={navigateToRecorder}
                className="text-xs sm:text-sm"
              >
                Record note {currentSession?.personal ? "" : "for session"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingDetailsViewer
