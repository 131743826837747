import {
  collection,
  doc,
  DocumentReference,
  FieldValue,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore"
import { NoteStatus } from "~/pages/Notes/types"
import { UserStatisticsType } from "~/types/userTypes"
import { getDefaultComment, getDefaultTitle } from "~/utils/helperFunctions"
import { db } from "./firebase"

export const updateNote = async (
  currentUserId: string,
  noteId: string,
  updates: { [x: string]: FieldValue | Partial<unknown> }
) => {
  const noteRef = doc(db, `users/${currentUserId}/notes/${noteId}`)
  await updateDoc(noteRef, updates)
}

interface CreateNoteParams {
  currentUserId: string
  noteId?: string
  noteStatus: NoteStatus
  userStatistics?: UserStatisticsType
  dictationLanguage?: string
  duration?: number
  clientId?: string
  clientName?: string
  title?: string
  sessionId?: string
  sessionStart?: Timestamp
}

// Create a new note document in Firestore
export async function createNewNote({
  currentUserId,
  noteId,
  noteStatus,
  userStatistics,
  dictationLanguage = "",
  duration = 0,
  clientId,
  clientName,
  title,
  sessionId,
  sessionStart,
}: CreateNoteParams): Promise<DocumentReference> {
  const note = {
    title: getDefaultTitle(userStatistics),
    comment: getDefaultComment(userStatistics),
    createdAt: Timestamp.fromDate(new Date()),
    serverTimestamp: serverTimestamp(),
    status: noteStatus,
    duration: duration,
    language: dictationLanguage,
    viewed: false,
    ...(clientId && { clientId }), // only add clientId if it exists
    ...(clientName && { clientName }),
    ...(title && { title }),
    ...(sessionId && { sessionId }),
    ...(sessionStart && { sessionStart }),
  }

  const noteDocRef = noteId
    ? doc(db, `users/${currentUserId}/notes/${noteId}`)
    : doc(collection(db, `users/${currentUserId}/notes`))

  await setDoc(noteDocRef, note)
  return noteDocRef
}
