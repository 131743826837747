import { useState } from "react"
import { DateTime, Duration } from "luxon"
import { toast } from "~/components/ui"
import {
  BookingProvider,
  BookingState,
  useBooking,
} from "~/context/BookingContext"
import {
  useAddSession,
  useDeleteSession,
  useUpdateSession,
  type Session,
} from "~/hooks/firestore/useSessions"
import { useStore } from "~/hooks/useStore"
import BookingDetails from "./BookingDetails"
import BookingDetailsViewer from "./BookingDetailsViewer"
import WeekView from "./WeekView"

const CalendarInner = () => {
  const { currentSession, resetCurrentSession, updateCurrentSession } =
    useBooking()
  const [setNavIsOpened] = useStore((state) => [state.setNavIsOpened])
  const [bookingState, setBookingState] = useState<BookingState>("none")

  const addSession = useAddSession({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Could not save the calendar event. Please try again.")
      }
    },
  })

  const updateSession = useUpdateSession({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Unable to update the calendar event")
      }
    },
  })

  const deleteSession = useDeleteSession({
    onSettled: (_, error) => {
      if (error) {
        toast.error("Unable to delete calendar event")
      }
    },
  })

  const handleConfirm = (session: Session) => {
    if (bookingState === "new") {
      addSession.mutate({ session })
    } else {
      updateSession.mutate({ sessionId: session.id, session: session })
    }
    resetCurrentSession()
  }

  const handleDeleteCurrentSession = () => {
    if (currentSession) {
      deleteSession.mutate({ sessionId: currentSession.id })
      resetCurrentSession()
      setBookingState("none")
    }
  }

  const handleBookingSelected = (booking: Session) => {
    if (!booking.duration) {
      let duration = Duration.fromObject({ hours: 1 })
      if (booking.end) {
        duration = Duration.fromDurationLike(
          booking.end.toMillis() - booking.start.toMillis()
        )
      }
      booking.duration = duration
    }
    resetCurrentSession()
    updateCurrentSession(booking)
    setBookingState("view")
  }

  const handleOpenSlotSelected = (date: DateTime) => {
    if (bookingState === "view") {
      setBookingState("none")
      resetCurrentSession()
      return
    }
    if (currentSession && (bookingState === "new" || bookingState === "edit")) {
      updateCurrentSession({
        start: date,
      })
    } else {
      setBookingState("new")
      updateCurrentSession({
        id: crypto.randomUUID(),
        start: date,
        duration: Duration.fromObject({ minutes: 60 }),
        personal: true,
      })
    }
  }

  const onCloseBooking = () => {
    setBookingState("none")
    resetCurrentSession()
  }

  const handleNavOpen = () => {
    setNavIsOpened(true)
  }

  return (
    <div className="h-screen flex flex-col bg-primary-cream-300">
      <div className={`${currentSession ? "h-[50%] md:h-[60%]" : "h-full"}`}>
        <WeekView
          onOpenSlotSelected={handleOpenSlotSelected}
          onBookingSelected={handleBookingSelected}
          onNavOpen={handleNavOpen}
        />
      </div>
      <div
        className={`${currentSession ? "h-[50%] md:h-[40%] sm:mx-auto mt-0 md:mt-2" : "hidden"}`}
      >
        {bookingState === "view" ? (
          <BookingDetailsViewer
            onClose={onCloseBooking}
            onEdit={() => setBookingState("edit")}
            onDelete={handleDeleteCurrentSession}
          />
        ) : (
          <BookingDetails
            onConfirm={handleConfirm}
            onClose={onCloseBooking}
            onDelete={handleDeleteCurrentSession}
            state={bookingState}
          />
        )}
      </div>
    </div>
  )
}

const Calendar = () => {
  return (
    <BookingProvider>
      <CalendarInner />
    </BookingProvider>
  )
}

export default Calendar
