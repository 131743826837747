import React from "react"
import { type Session } from "~/hooks/firestore/useSessions"

interface SelectedSlotProps {
  booking: Session
  slotHeight: number
}

const MIN_SLOT_HEIGHT = 10

const SelectedSlot: React.FC<SelectedSlotProps> = ({ booking, slotHeight }) => {
  const top = (booking.start.minute / 60) * slotHeight
  const height = Math.max(
    (booking.duration.as("minutes") / 60) * slotHeight,
    MIN_SLOT_HEIGHT
  )

  return (
    <div
      className={`absolute inset-0 bg-primary text-primary-foreground
        ${height < 30 ? "rounded-sm" : "rounded-md"}
        z-10 flex items-center justify-center text-[8px] sm:text-xs`}
      style={{
        top: `${top}px`,
        height: `${height}px`,
      }}
    >
      {!booking.title ? (
        <p>New event</p>
      ) : (
        <div className="flex flex-col w-full justify-center items-center">
          {booking.title && <p>{booking.title}</p>}
        </div>
      )}
    </div>
  )
}

export default SelectedSlot
