import * as React from "react"
import { toolbarPlugin } from "@mdxeditor/editor"
import { useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import {
  Button,
  ConfirmationModal,
  Credenza as Dialog,
  CredenzaClose as DialogClose,
  CredenzaContent as DialogContent,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  CredenzaTrigger as DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
  SimpleEditorToolbar,
  SubmitButton,
  toast,
} from "~/components/ui"
import { useBeforeunload } from "~/hooks/useBeforeunload"
import { useTranscribedNote, useUpdateNote } from "~/hooks/useNotes"
import { Note, NoteStatus } from "~/pages/Notes/types"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"
import { parseTranscript } from "~/utils/transcriptParsers"

export function parseTranscribed(note: Note) {
  switch (note.status) {
    case NoteStatus.Edited:
      return note.editedTranscription.replace(/\\n/g, "\n")
    case NoteStatus.Improved:
      return note.improvedTranscription.replace(/\\n/g, "\n")
    case NoteStatus.ImprovementRequested:
      // @note: lazy load the improved transcription later
      return ""
    case NoteStatus.Transcribed:
      return parseTranscript(note.transcription)
    case NoteStatus.Error:
      return `Error transcribing note \n\n ${note.transcription}`
    default:
      return "Not transcribed yet"
  }
}

type EditNoteModalProps = {
  note: Note
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export function EditNoteModal({
  note,
  isOpen,
  onOpenChange,
  children,
}: React.PropsWithChildren<EditNoteModalProps>) {
  const queryClient = useQueryClient()

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  const isImprovementRequested = note.status === NoteStatus.ImprovementRequested
  const transcribed = useTranscribedNote({
    note,
    reactQuery: {
      enabled: isImprovementRequested,
    },
  })

  const initialValues = React.useMemo(
    () => ({
      transcription:
        note.status === NoteStatus.ImprovementRequested
          ? (transcribed.data ?? "")
          : parseTranscribed(note),
    }),
    [note, transcribed.data]
  )

  const transcription = initialValues.transcription
  const form = useForm({
    values: {
      transcription,
    },
  })

  // MDXEditor is not a controlled component, so we need to set the markdown
  // by manually.
  React.useEffect(() => {
    if (transcription) {
      editorRef.current?.setMarkdown(transcription)
    }
  }, [transcription])

  /* Actions */
  const updateNote = useUpdateNote({
    onSuccess: async () => {
      toast.success("Note updated successfully", {
        id: "note-updated",
      })

      onOpenChange?.(false)
      await queryClient.invalidateQueries({ queryKey: ["NOTE", note.id] })
    },
  })

  // Focus the editor when the modal is opened
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => editorRef.current?.focus(), 100)
    }
  }, [isOpen])

  const isDirty = !!form.formState.dirtyFields?.transcription
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false)

  useBeforeunload(() => {
    if (isDirty) {
      return "You have changes to a note that will be lost."
    }
  })

  const discardChanges = () => {
    form.reset({ ...initialValues })
    // We need to do a more step here, reset the MDXEditor
    editorRef.current?.setMarkdown(initialValues.transcription)
  }

  return (
    <>
      <Dialog
        shouldScaleBackground={false}
        open={isOpen}
        onOpenChange={(open) => {
          if (updateNote.isPending) return

          if (!open && isDirty) {
            setIsWarningModalOpen(true)
            return
          }

          onOpenChange?.(open)
        }}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent
          indicator
          aria-describedby=""
          overlayClassName={"overflow-y-auto p-0"}
          className={
            "md:rounded-none border-0 after:!content-none md:inset-x-auto md:bottom-auto md:w-full md:max-w-full h-[90%] md:h-full"
          }
          onKeyPress={(e) => {
            if (!e.target?.hasAttribute?.("contenteditable")) {
              editorRef.current?.focus()
            }
          }}
        >
          <DialogHeader className="sr-only">
            <DialogTitle>Edit Note</DialogTitle>
          </DialogHeader>

          <Form
            ref={formRef}
            form={form}
            data-vaul-no-drag=""
            className="flex flex-col bg-white w-full h-full lg:w-[860px] mx-auto p-4 md:p-0"
            onSubmit={form.handleSubmit(async (values) => {
              await updateNote.mutateAsync({
                noteId: note.id,
                editedTranscription: `${values.transcription ?? ""}`.trim(),
              })

              form.reset()
            })}
          >
            <div className="shrink-0 flex items-center justify-between mb-4">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="default"
                  data-invisible={!isDirty ? "true" : undefined}
                  className="rounded-full min-w-[100px] min-h-[49px] bg-transparent_gray text-black hover:bg-transparent_gray data-[invisible]:invisible"
                >
                  Cancel
                </Button>
              </DialogClose>

              <div className="text-center">
                <h2 className="font-sans text-[17px] text-primary-black leading-snug font-bold">
                  {note?.clientId ? note?.title : "Note"}
                </h2>
                <p className="flex items-center justify-center gap-1 text-gray-500 font-sans text-[17px] leading-snug">
                  <span className="capitalize">
                    {formatTimestampAsNoteTitleDatePart(note.createdAt)}
                  </span>
                  <span> · </span>
                  <span className="font-medium">
                    {formatTimestampAsNoteTitleTimePart(note.createdAt)}
                  </span>
                </p>
              </div>

              <SubmitButton
                type="submit"
                variant="secondary"
                className="rounded-full min-w-[100px] min-h-[49px]"
                isSubmitting={form.formState.isSubmitting}
                onClick={() => {
                  if (isDirty) {
                    formRef.current?.requestSubmit?.()
                  } else {
                    onOpenChange?.(false)
                  }
                }}
              >
                {isDirty ? "Save" : "Done"}
              </SubmitButton>
            </div>

            <FormField
              control={form.control}
              name="transcription"
              rules={{
                required: "Transcription is required",
              }}
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormControl>
                    <RichEditor
                      {...field}
                      ref={(ref) => {
                        field.ref(ref)
                        editorRef.current = ref
                      }}
                      readOnly={isImprovementRequested && transcribed.isLoading}
                      onRequestSave={() => {
                        if (form.formState.isDirty) {
                          formRef.current?.requestSubmit?.()
                        }
                      }}
                      plugins={[
                        toolbarPlugin({
                          toolbarClassName:
                            "mdx-toolbar bg-light_gray/80 backdrop-blur rounded-md px-2.5 py-2 sticky top-auto bottom-0 inset-x-0 mx-auto w-[102px] mb-4 overflow-hidden",
                          toolbarContents: SimpleEditorToolbar,
                        }),
                      ]}
                      placeholder={"Edit your note here"}
                      className="flex flex-col-reverse !border w-full h-full rounded-md"
                      contentEditableClassName="font-sourceSerifPro leading-[31.16px] text-[19px] overflow-auto"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Form>
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        isOpen={isWarningModalOpen}
        onOpenChange={setIsWarningModalOpen}
        title="Unsaved changes"
        description="Unsaved changes will be lost. Are you sure you want to close?"
        closeButton="Discard Changes"
        confirmButton="Save"
        onConfirm={() => {
          formRef.current?.requestSubmit?.()
          onOpenChange?.(false)
          setIsWarningModalOpen(false)
        }}
        onClose={() => {
          discardChanges()
          onOpenChange?.(false)
          setIsWarningModalOpen(false)
        }}
      />
    </>
  )
}
