import React, { useEffect } from "react"
import { ChevronLeft, Trash2Icon, X } from "lucide-react"
import { DateTime, Duration } from "luxon"
import { toast } from "~/components/ui/"
import { Button } from "~/components/ui/button"
import { ConfirmationModal } from "~/components/ui/confirmation-modal"
import { BookingState, useBooking } from "~/context/BookingContext"
import { useClientById } from "~/hooks/firestore/useClients"
import { type Session } from "~/hooks/firestore/useSessions"
import TimeDetails from "./TimeDetails"
import TitleClientDetails from "./TitleClientDetails"

interface BookingDetailsProps {
  onConfirm: (slot: Session) => void
  onClose: () => void
  onDelete: () => void
  state: BookingState
}

interface ClientData {
  id?: string
  name?: string
  phone?: string
  email?: string

  [key: string]: string | undefined
}

const BookingDetails: React.FC<BookingDetailsProps> = ({
  onConfirm,
  onClose,
  onDelete,
  state,
}) => {
  const { currentSession } = useBooking()
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false)
  const [showTimeDetails, setShowTimeDetails] = React.useState(true)
  const start = currentSession?.start ?? DateTime.now()
  const duration =
    currentSession?.duration ?? Duration.fromObject({ minutes: 60 })
  const [clientId, setClientId] = React.useState<string>(
    currentSession?.clientId || ""
  )
  const client = useClientById({
    clientId,
    reactQuery: {
      enabled: Boolean(clientId),
    },
  })

  useEffect(() => {
    if (!currentSession) {
      setShowConfirmDelete(false)
    }
    setClientId(currentSession?.clientId || "")
  }, [currentSession])

  const clientData = React.useMemo(
    () => (client.data as ClientData) ?? {},
    [client.data]
  )

  const handleDelete = () => {
    onDelete()
  }

  const handleConfirm = () => {
    let slot: Session | undefined = undefined
    if (state === "new") {
      slot = {
        id: crypto.randomUUID(),
        start: start,
        end: start.plus(duration),
        duration: duration,
        title: currentSession?.title ?? "",
        description: currentSession?.description ?? "",
        personal: currentSession?.personal ?? false,
        clientId: currentSession?.clientId,
        clientName: clientData.name,
      }
    } else if (start && duration && state === "edit") {
      slot = {
        id: currentSession?.id ?? crypto.randomUUID(),
        start: start,
        end: start.plus(duration),
        duration: duration,
        title: currentSession?.title ?? "",
        description: currentSession?.description ?? "",
        personal: currentSession?.personal ?? false,
        clientId: currentSession?.clientId,
        clientName: clientData.name,
      }
    }
    if (slot) {
      onConfirm(slot)
    } else {
      toast.error("Unable to create or edit slot")
      onClose()
    }
    setShowTimeDetails(true)
  }

  const handleClose = () => {
    setShowTimeDetails(true)
    onClose()
  }

  const handleNext = () => {
    setShowTimeDetails(false)
  }

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmDelete}
        onOpenChange={setShowConfirmDelete}
        title={
          currentSession?.personal ? "Delete event?" : "Delete client session?"
        }
        description=""
        confirmButton="Delete"
        closeButton="Cancel"
        onConfirm={handleDelete}
      />

      <div className=" sm:mx-4 flex flex-col justify-center item-center h-full w-full sm:w-[600px]">
        <div className="px-2 sm:px-4 pb-4 pb-4 flex flex-col self-center gap-2 sm:gap-4 w-full h-full overflow-y-auto">
          <div className="sm:pt-1 flex items-center justify-between self-center w-full ">
            <h2
              className={`text-primary-black transition-text text-[1.5rem] font-medium leading-normal tracking-tighter font-platypi truncate`}
            >
              {state === "new" && clientData.name && clientData.name}
              {state === "new" && !clientData.name && "New event"}
              {state === "edit" && clientData.name && clientData.name}
              {state === "edit" &&
                !clientData.name &&
                currentSession?.title &&
                currentSession.title}
            </h2>
            <Button
              variant="ghost"
              onClick={handleClose}
              className=""
            >
              <X className="h-4 w-4" />
            </Button>
          </div>

          <div className="flex flex-col justify-center self-center w-full gap-4 px-1">
            {showTimeDetails ? (
              <TimeDetails
                start={start}
                duration={duration}
              />
            ) : (
              <TitleClientDetails
                clientName={clientData.name}
                onTitleEnter={handleConfirm}
              />
            )}

            <div className="flex flex-col w-full gap-1">
              {showTimeDetails ? (
                <div className="flex flex-row-reverse w-full justify-between">
                  <Button
                    onClick={handleNext}
                    variant={"secondary"}
                    className="rounded-3xl w-[164px]"
                  >
                    Next
                  </Button>

                  <Button
                    variant={"ghost"}
                    onClick={(e) => {
                      e.preventDefault()
                      setShowConfirmDelete(true)
                    }}
                    className={`text-sm text-red-500 hover:text-red-500 mx-[-16px] ${state === "edit" ? "" : "hidden"}`}
                  >
                    <Trash2Icon className="size-4 text-red-500" />
                    Delete
                  </Button>
                </div>
              ) : (
                <div className="flex flex-row w-full justify-between">
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setShowTimeDetails(true)
                    }}
                    className="mx-[-16px]"
                  >
                    <ChevronLeft className="h-4 w-4" />
                    Duration
                  </Button>

                  <div className="flex flex-row item-center justify-center gap-2 sm:gap-4">
                    <div className="flex flex-col h-full">
                      <p className="capitalize w-full text-sm truncate text-start">
                        {start?.toLocaleString({
                          month: "short",
                          day: "2-digit",
                          weekday: "short",
                        })}
                      </p>
                      <p className="capitalize w-full text-sm truncate text-start">
                        {start?.toLocaleString(DateTime.TIME_SIMPLE) +
                          " - " +
                          start
                            ?.plus(
                              duration ?? Duration.fromObject({ minutes: 0 })
                            )
                            .toLocaleString(DateTime.TIME_SIMPLE)}
                      </p>
                    </div>
                    <Button
                      onClick={handleConfirm}
                      variant={"secondary"}
                      className="rounded-3xl w-[130px] sm:w-[164px]"
                    >
                      {state === "edit" ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingDetails
