import { ChevronLeft } from "lucide-react"
import { Link } from "react-router-dom"
import { useAnalytics } from "use-analytics"
import { Button } from "~/components/ui"

interface NoteHeaderProps {
  noteData:
    | {
        clientId?: string
        clientName?: string
        title?: string
      }
    | undefined
  setOpenClientDetailsModal: (value: boolean) => void
  setOpenModal: (value: boolean) => void
}

function NoteHeader({
  noteData,
  setOpenClientDetailsModal,
  setOpenModal,
}: NoteHeaderProps) {
  const { track } = useAnalytics()

  const handleOpenClientDetailsModal = () => {
    setOpenClientDetailsModal(true)
    void track("Note Client_details_shown_from_note_header")
  }

  const handleOpenClientModal = () => {
    setOpenModal(true)
    void track("Note Client_modal_opened_from_note_header")
  }

  return (
    <div className="fixed z-40 md:relative bottom-0 left-0 right-0 notes-title-bg flex w-full h-20 p-6 justify-between items-center">
      <Link to="/notes">
        <ChevronLeft
          size={28}
          className="text-black"
        />
      </Link>
      {noteData?.clientId ? (
        <button
          className="text-2xl font-bold text-black font-platypi cursor-pointer capitalize truncate max-w-[80%] bg-transparent border-none"
          onClick={handleOpenClientDetailsModal}
        >
          {noteData?.clientName ?? noteData?.title}
        </button>
      ) : (
        <Button
          variant="secondary"
          className="py-3 px-5 rounded-lg"
          onClick={handleOpenClientModal}
        >
          Set Client
        </Button>
      )}
    </div>
  )
}

export default NoteHeader
