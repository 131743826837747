import { useMemo, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import LogOut from "~/assets/icons/logout-icon.svg?react"
import Menu from "~/assets/icons/more-icon.svg?react"
import BannerMessage from "~/components/BannerMessage"
import Header from "~/components/Header"
import MainDrawer from "~/components/MainDrawer"
import TrialNoticeHeader from "~/components/TrialNoticeHeader"
import {
  Button,
  ConfirmationModal,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui"
import useSafeLogout from "~/hooks/useSafeLogout"
import { useStore } from "~/hooks/useStore"
import "./main.css"
import { useAnalytics } from "use-analytics"

export default function Main() {
  const [setNavIsOpened] = useStore((state) => [state.setNavIsOpened])
  const location = useLocation()
  const { localRecordingsFound, tryLogout, forceLogout } = useSafeLogout()
  const { track } = useAnalytics()

  const [showLocalRecordingsAlert, setShowLocalRecordingsAlert] =
    useState(false)

  const confirmLogout = async () => {
    setShowLocalRecordingsAlert(false)
    await forceLogout()
  }

  const handleLogout = async () => {
    void track("Main Logout")
    const res = await tryLogout()
    if (!res && localRecordingsFound) {
      setShowLocalRecordingsAlert(true)
    }
  }

  const showTopBar = useMemo(() => {
    return location.pathname === "/notes" || location.pathname === "/account"
  }, [location])

  const showBanner = useMemo(() => {
    return location.pathname === "/notes"
  }, [location])

  return (
    <div className="h-full w-full bg-soft_green-500">
      <ConfirmationModal
        isOpen={showLocalRecordingsAlert}
        onOpenChange={setShowLocalRecordingsAlert}
        title="Local recordings available"
        description="You have recordings that have not yet been uploaded. They will be deleted if you logout before upload is finished. Are you sure you want to logout?"
        confirmButton="Confirm"
        closeButton="Cancel"
        onConfirm={confirmLogout}
      />

      <div
        className={`main-content-bg main-content-shadow flex flex-col m-auto h-full`}
      >
        <MainDrawer />

        {showBanner && <BannerMessage />}

        <Header
          classes={`w-full bg-primary-cream-300 ${!showTopBar && "hidden"}`}
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => setNavIsOpened(true)}
                size="icon"
                variant="ghost"
                className="hover:bg-[#F0F4F8]"
              >
                <Menu />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Menu</TooltipContent>
          </Tooltip>

          <TrialNoticeHeader classes="bg-primary-cream-300 py-1" />

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={handleLogout}
                size="icon"
                variant="ghost"
                className="hover:bg-[#E3EFFB]"
              >
                <LogOut className="mr-1 fill-black" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Logout</TooltipContent>
          </Tooltip>
        </Header>

        <div className="flex-1 overflow-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
